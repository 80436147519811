
import store from '@/store';

export const userRoles = (item) => {
  const master = store.getters.getUserData.master;
  const roles = store.getters.getUserRole.authority;

  if (master === 'admin') {
    return true;
  } else {
    for (const key in roles) {
      if (item.route === 'dashboard') return true;
      if (item.route === 'firmalar') return false;
      if (roles[key].modul == 'magaza-yonetim') {
        if (
          item.route === 'sayfalar' ||
          item.route === 'sayfa-listele' ||
          item.route === 'slider' ||
          item.route === 'banner' ||
          item.route === 'splash-bildirimler' ||
          item.title === 'Mağaza İşlemleri'
        ) {
          return roles[key]['listeleme'];
        }
      }
      if (roles[key].modul == 'rezervasyon-islemleri') {
        if (
          item.title === 'Rezervasyon İşlemleri' ||
          item.route === 'rezervasyonlar' ||
          item.route === 'rezervasyon-listele' ||
          item.route == 'odeme-bildirimler' ||
          item.route == 'ozel-talep-listele' ||
          item.route == 'iptal-talep-listele' ||
          item.route == 'on-talep-listele'
        )
          return roles[key]['listeleme'];
      }
      if (roles[key].modul == 'urun-islemleri') {
        if (
          item.title === 'Ürün İşlemleri' ||
          item.route == 'urunler' ||
          item.route == 'urun-listele' ||
          item.route == 'transfer-urunler' ||
          item.route == 'transfer-urun-listele' ||
          item.route == 'urun-takvimi' ||
          item.route == 'urun-takvim-listele'
        )
          return roles[key]['listeleme'];
      }

      if (roles[key].modul == 'finans-islemleri') {
        // return false;
      }
      if (roles[key].modul == 'acente-islemleri') {
        // return false;
        if (item.route === 'acente-listele') return roles[key]['listeleme'];
      }
      if (roles[key].modul == 'tanimlamalar') {
        if (
          item.title === 'Tanımlamalar' ||
          item.route === 'kategoriler' ||
          item.route === 'bolgeler' ||
          item.route === 'hoteller' ||
          item.route === 'hotel-listele' ||
          item.route === 'transfer-islemleri' ||
          item.route === 'tarife-bolgeleri' ||
          item.route === 'urun-ozel-alan' ||
          item.route === 'ekstra-hizmetler' ||
          item.route === 'stok-birimleri' ||
          item.route === 'varyant-gruplari' ||
          item.route === 'renk-tanimlari' ||
          item.route === 'rezervasyon-durumlari' ||
          item.route === 'urun-ek-ozellikler' ||
          item.route === 'uye-gruplari' ||
          item.route === 'kargo' ||
          item.route === 'kampanyalar' ||
          item.route === 'kuponlar'
        )
          return roles[key]['listeleme'];
      }
      if (roles[key].modul == 'destek-islemleri') {
        // return false;
        if (item.route === 'destek-hatti' || item.route === 'destek-hatti-listele') return roles[key]['listeleme'];
      }
      if (roles[key].modul == 'raporlar') {
        if (
          item.title === 'Raporlar' ||
          item.route === 'yorumlar' ||
          item.route === 'yorum-listele' ||
          item.route === 'uyeler' ||
          item.route === 'uye-listele' ||
          item.route === 'siparis-raporu' ||
          item.route === 'urun-satis-raporu' ||
          item.route === 'stok-risk-raporu' ||
          item.route === 'urun-haber-ver-raporu' ||
          item.route === 'abone-olan-rapor-listele'
        )
          return roles[key]['listeleme'];
      }
      if (roles[key].modul == 'kullanicilar') {
        if (
          item.title === 'Kullanıcılar' ||
          item.route === 'kullanici-listele' ||
          item.route === 'kullanici-yetkiler' ||
          item.route === 'acente-kullanici-listele' ||
          item.route === 'acente-kullanici-yetkiler'
        ) {
          return roles[key]['listeleme'];
        }
        if (item.route === 'profilim') {
          return roles[key]['duzenleme'];
        }
      }
      if (roles[key].modul == 'ayarlar') {
        if (item.route === 'ayarlar' || item.route === 'tema-ayar') return roles[key]['listeleme'];
      }
    }
    return false;
  }
};
